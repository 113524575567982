var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "monitor-template",
    {
      ref: "monitorRef",
      attrs: { mapPageParams: _vm.mapPageParams },
      on: {
        mapPageClick: _vm.handleMapPageClick,
        mapToolClick: _vm.handleMapToolClick,
      },
    },
    [
      _c(
        "div",
        { attrs: { slot: "left" }, slot: "left" },
        [
          _c(
            "vxe-modal",
            {
              staticClass: "my-modal",
              attrs: {
                title: "传统车辆监控中心",
                width: "390px",
                height: "90%",
                "show-zoom": "",
                resize: "",
                "lock-view": false,
                mask: false,
                position: { top: "4%", left: "8%" },
              },
              on: { close: _vm.handleCloseLeftModal },
              model: {
                value: _vm.showLeftModal,
                callback: function ($$v) {
                  _vm.showLeftModal = $$v
                },
                expression: "showLeftModal",
              },
            },
            [
              [
                _c(
                  "div",
                  { staticClass: "vechicle-center" },
                  [
                    _c("object-tree", {
                      ref: "vehicleTree",
                      staticStyle: { height: "55%" },
                      attrs: {
                        "tree-data": _vm.treeData,
                        "place-holder": "输入车辆编号进行搜索",
                        "object-title": "车辆",
                        "cache-key": "traditionalVehicleTree",
                        "object-icon": "carIcon",
                        "tree-selected-key": _vm.currentSelectedId,
                        onlineImgSrc: _vm.onlineImgSrc,
                        offlineImgSrc: _vm.offlineImgSrc,
                        onlineHint: "该车当前在线",
                        offlineHint: "该车当前离线",
                        showFilterBtn: "",
                      },
                      on: {
                        select: _vm.clickTraditionalVehicleNode,
                        check: _vm.checkTraditionalVehicleNode,
                        filter: function ($event) {
                          return _vm.handleFilterTreeData($event, 1)
                        },
                      },
                    }),
                    _c("a-divider", {
                      staticStyle: { height: "1px", margin: "5px 0" },
                    }),
                    _c("a-row", {
                      attrs: { type: "flex", justify: "space-around" },
                    }),
                    _c("a-divider", {
                      staticStyle: { height: "1px", margin: "5px 0" },
                    }),
                    _c(
                      "a-row",
                      [
                        _c(
                          "a-col",
                          { attrs: { span: 24 } },
                          [
                            _c(
                              "a-tabs",
                              {
                                attrs: { type: "card", size: "small" },
                                on: { change: _vm.handleTabChange },
                              },
                              [
                                _c(
                                  "a-tab-pane",
                                  { key: "1", attrs: { tab: "实时状态" } },
                                  [
                                    _c("MapTraditionalVehicleInfoWindow", {
                                      attrs: {
                                        "map-obj": _vm.MapObj,
                                        objectInfo: _vm.objectInfo,
                                        "show-footer": false,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "a-tab-pane",
                                  { key: "2", attrs: { tab: "历史轨迹" } },
                                  [
                                    _c("map-history-track", {
                                      attrs: {
                                        monitorRef: _vm.$refs.monitorRef,
                                        params: _vm.mapPageParams,
                                        historyTrackPointsArray:
                                          _vm.mapPageParams
                                            .historyTrackPointsArray,
                                      },
                                      on: {
                                        onHistoryTrackSearch:
                                          _vm.handleHistoryTrackSearch,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
            ],
            2
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }