<template>
  <monitor-template ref="monitorRef" :mapPageParams="mapPageParams" @mapPageClick="handleMapPageClick" @mapToolClick="handleMapToolClick">
    <div slot="left">
      <!-- 左侧弹窗 -->
      <vxe-modal
        v-model="showLeftModal"
        title="传统车辆监控中心"
        width="390px"
        height="90%"
        class="my-modal"
        @close="handleCloseLeftModal"
        show-zoom
        resize
        :lock-view="false"
        :mask="false"
        :position="{ top: '4%', left: '8%' }"
      >
        <template>
          <!-- 树形结构:保洁人员数据 -->
          <div class="vechicle-center">
            <object-tree
              style="height: 55%"
              ref="vehicleTree"
              :tree-data="treeData"
              place-holder="输入车辆编号进行搜索"
              object-title="车辆"
              cache-key="traditionalVehicleTree"
              object-icon="carIcon"
              :tree-selected-key="currentSelectedId"
              @select="clickTraditionalVehicleNode"
              @check="checkTraditionalVehicleNode"
              @filter="handleFilterTreeData($event, 1)"
              :onlineImgSrc="onlineImgSrc"
              :offlineImgSrc="offlineImgSrc"
              onlineHint="该车当前在线"
              offlineHint="该车当前离线"
              showFilterBtn
            />
            <a-divider style="height: 1px; margin: 5px 0" />
            <!-- 操作按钮区 -->
            <a-row type="flex" justify="space-around"> </a-row>
            <a-divider style="height: 1px; margin: 5px 0" />
            <a-row>
              <a-col :span="24">
                <a-tabs type="card" size="small" @change="handleTabChange">
                  <a-tab-pane key="1" tab="实时状态">
                    <MapTraditionalVehicleInfoWindow :map-obj="MapObj" :objectInfo="objectInfo" :show-footer="false" />
                  </a-tab-pane>
                  <a-tab-pane key="2" tab="历史轨迹">
                    <map-history-track
                      :monitorRef="$refs.monitorRef"
                      :params="mapPageParams"
                      :historyTrackPointsArray="mapPageParams.historyTrackPointsArray"
                      @onHistoryTrackSearch="handleHistoryTrackSearch"
                    />
                  </a-tab-pane>
                </a-tabs>
              </a-col>
            </a-row>
          </div>
        </template>
      </vxe-modal>
    </div>
  </monitor-template>
</template>
<script>
import MonitorTemplate from '../../../monitorTemplate'
import MapHistoryTrack from '../../../monitorComponents/mapHistoryTrack'
// 车辆树
import ObjectTree from '../../../monitorComponents/objectTree'
import MapTraditionalVehicleInfoWindow from '../../../monitorComponents/mapInfoWindow/traditional/vehicle/object'
import { Tabs } from 'ant-design-vue'
import { queryVehicleDeviceStatus } from '@/api/jt808/deviceStatus'
import { queryHistoryCoordinates } from '@/api/jt808/deviceLocation'
// import { vehicleAccountTree } from '@/api/iot/vehicleAccount'
import { deviceTree } from '@/api/iot/device'
import { VehiclePlayIcon } from '@/views/monitor/monitorComponents/utils/mapUtil'
import MonitorBase from '@/views/monitor/monitorBase'
export default {
  mixins: [MonitorBase],
  name: 'MonitorTraditionalVehicle',
  components: {
    MonitorTemplate,
    ObjectTree,
    MapHistoryTrack,
    MapTraditionalVehicleInfoWindow,
    ATabs: Tabs,
    ATabPane: Tabs.TabPane
  },
  data() {
    return {
      mapPageParams: {
        timingUpdate: true,
        showHeader: true,
        objectId: 'deviceId',
        infoWindowTitle: 'bindName',
        // 显示轨迹明细按钮
        showMapTrackDetailButton: false,
        moduleKey: 'traditionalVehicle',
        mapDataSource: 'real',
        // 地图上左侧按钮显示文字
        leftModalTitle: '车辆监控中心',
        // 地图上右侧按钮显示文字
        rightModalTitle: '',
        // 传统车辆显示图标
        objectIcon: { url: require('@/assets/images/map/vehicle-sweeper.png'), size: { width: 80, height: 60 } },
        // 车辆离线图标
        objectIconOff: { url: require('@/assets/images/map/vehicle-off.png'), size: { width: 80, height: 60 } },
        // 保洁人员当前位置分布及相关人员信息
        objectPoints: [],
        historyTrackPointsArray: [],
        lushuConfig: {
          playIcon: VehiclePlayIcon,
          // 不需要控制车辆方向
          enableRotation: true,
          // 轨迹播放时ICON上方显示的文字
          defaultContent: '',
          // 图标大小
          iconSize: { width: 52, height: 26 },
          // 图标的定位点相对于图标左上角的偏移值
          anchorSize: { width: 27, height: 14 }
        },
        // 是否显示地图轨迹明细
        showMapTrackDetail: false,
        mapTool: {
          timer: {
            timerValue: 5,
            timerOptions: [
              {
                id: 5,
                label: '5s'
              },
              {
                id: 10,
                label: '10s'
              },
              {
                id: 20,
                label: '20s'
              },
              {
                id: 30,
                label: '30s'
              }
            ]
          }
        },
        // 当前选中的轨迹对象
        currentTrackObject: null,
        // 被选中的车移动距离（当前位置距离上一次更新的位置距离）超过多少米后才能移动位置并重置弹窗
        // 但弹窗信息必须实时更新
        selectedObjectMoveDistanceForResetLocation: 2
      },
      showLeftModal: true,
      treeData: [],
      // 当前选中的设备Id
      currentSelectedId: '',
      // 当前打勾的设备列表
      currentCheckedList: [],
      // 车辆状态信息
      vehicleInfoArray: [],
      // 当前选中的IMEI列表
      selectedImeiArray: [],
      currentActiveTabKey: '1',
      // 当前地图实例对象
      currentMapObj: null,
      // 当前选中的物体对象
      objectInfo: null,
      deviceVehicleArray: [],
      // 地图对象
      MapObj: null,
      // 最新对象节点数据
      recentObjectPoints: [],
      onlineImgSrc: require('@/assets/images/vehicle-online.png'),
      offlineImgSrc: require('@/assets/images/vehicle-offline.png'),
      isAll: true,
      onlineList: [],
      // 树对象在线状态Map
      treeObjectOnlineMap: new Map()
    }
  },
  watch: {
    currentCheckedList(newVal, oldVal) {
      this.getData()
    }
  },
  created() {
    this.initTraditionalVehicleTree()
  },
  methods: {
    handleTabChange(activeKey) {
      this.currentActiveTabKey = activeKey
      if (activeKey === '2') {
        // 历史轨迹地图
        this.mapPageParams.mapDataSource = 'track'
        this.mapPageParams.showMapTrackDetailButton = true
      } else {
        // 实时状态地图
        this.mapPageParams.mapDataSource = 'real'
        this.mapPageParams.showMapTrackDetailButton = false
        this.mapPageParams.showMapTrackDetail = false
      }
      if (activeKey === '1') {
        this.mapPageParams.historyTrackPointsArray = []
        this.handleMapTrackPlayStop()
        this.getData()
      }
    },
    handleCloseLeftModal() {
      this.showLeftModal = false
    },
    initTraditionalVehicleTree() {
      const formData = new FormData()
      formData.append('deviceType', 1)
      deviceTree(formData).then((response) => {
        this.deviceVehicleArray = []
        // this.setDeviceVehicleArray(response.data)
        this.allTreeData = response.data
        const data = JSON.parse(JSON.stringify(this.allTreeData))
        // 初始化所有车辆都不在线
        this.treeObjectOnlineMap.clear()
        this.initTreeData(data, 0)
        this.treeData = data
        console.log('treeObjectOnlineMap', this.treeObjectOnlineMap)
      })
    },
    setDeviceVehicleArray(data) {
      if (!data || data.length === 0) {
        return false
      }
      data.forEach((p) => {
        if (p && p.id.indexOf('t-1-') >= 0) {
          this.deviceVehicleArray.push({
            deviceId: p.id.substr(4),
            name: p.label
          })
        }
        if (p.children && p.children.length > 0) {
          this.setDeviceVehicleArray(p.children)
        }
      })
    },
    // 点击选中设备，当前选中Id，当前打勾设备列表
    clickTraditionalVehicleNode(selectedId, checkedList, isSameList) {
      console.log('clickTraditionalVehicleNode', selectedId)
      this.currentCheckedList = checkedList
      this.currentSelectedId = selectedId
      // 手动控制是否显示弹窗信息
      this.$refs.monitorRef.mapShowObjectInfo(selectedId, isSameList)
      // 先判断打勾列表是否发生变化，如果不变，则不重新加载后台数据
      if (this.currentCheckedList.toString() === checkedList.toString()) {
        // console.log('打勾列表不变')
        this.toSelectObject()
      }
    },
    // 选中物体
    toSelectObject() {
      const data = this.recentObjectPoints
      if (data) {
        const obj = data.find((p) => p.deviceId === this.currentSelectedId)
        if (obj && this.objectInfo && obj.deviceId === this.objectInfo.deviceId) {
          // 如果车辆Id相同，则直接更改对象属性值
          this.objectInfo = Object.assign(this.objectInfo, obj)
        } else {
          this.objectInfo = obj
        }
        if (this.mapPageParams.mapDataSource === 'track') {
          this.mapPageParams.currentTrackObject = this.objectInfo
        }
        // 内存泄漏2：每次新对象赋值，会导致内存泄漏 2022-01-19
        // this.objectInfo = data.find((p) => p.deviceId === this.currentSelectedId)
      }
    },
    // 打勾选择设备，当前打勾的设备列表，当前选中的设备Id
    checkTraditionalVehicleNode(checkedList, selectedId, isSameList) {
      console.log('checkTraditionalVehicleNode', checkedList)
      this.currentCheckedList = checkedList
      this.currentSelectedId = selectedId
      this.$refs.monitorRef.mapShowObjectInfo(selectedId, isSameList)
    },
    getData() {
      // console.log('getData')
      if (this.currentActiveTabKey !== '1') {
        const obj = this.recentObjectPoints.find((p) => p.deviceId === this.currentSelectedId)
        if (obj) {
          return
        }
      }
      // 如果没有打勾的设备，则地图上不显示设备
      if (this.currentCheckedList.length === 0) {
        this.objectInfo = null
        this.mapPageParams.objectPoints = []
        return
      }

      // 如果没有选中设备，则地图上不显示设备的气泡
      if (!this.currentSelectedId) {
        this.objectInfo = null
      }

      queryVehicleDeviceStatus(this.currentCheckedList).then((res) => {
        if (res.data) {
          const data = res.data.map((p) => {
            return { ...p, speed: Number(p.speed) / 10, rotation: p.direction, lat: p.coordinates[1], lng: p.coordinates[0] }
          })
          this.recentObjectPoints = data

          //
          // 更新车辆树在线状态，并判断是否有车辆状态更新
          this.doUpdateTreeData(data)
          //

          this.$refs.monitorRef && this.$refs.monitorRef.mapUpdateObjectPoints(data)
          this.toSelectObject()
        }
      })
    },

    handleMapPageClick(eventName, eventArgs) {
      if (eventName === 'showLeftModal') {
        this.showLeftModal = eventArgs
      } else if (eventName === 'showMapTrackDetail') {
        this.mapPageParams.showMapTrackDetail = eventArgs
      } else if (eventName === 'selectObjectInfo') {
        // 地图上选中物体
        this.currentSelectedId = eventArgs.deviceId
        this.toSelectObject()
      } else if (eventName === 'setMapObj') {
        this.MapObj = eventArgs
      }
    },
    // 地图上方工具栏点击事件
    handleMapToolClick(eventName, eventArgs) {
      // 显示地图轨迹明细
      if (eventName === 'showMapTrackDetail') {
        this.mapPageParams.showMapTrackDetail = eventArgs
      } else if (eventName === 'refreshData') {
        this.getData()
      }
    },

    handleHistoryTrackSearch(eventArgs) {
      console.log('handleHistoryTrackSearch', eventArgs)
      if (!this.currentSelectedId) {
        this.$warningEx('请先选择中一辆车！')
        return
      }
      // 暂停轨迹播放
      this.handleMapTrackPlayStop()
      this.loading = true
      const queryParam = {}
      queryParam.startTime = eventArgs.startTime
      queryParam.endTime = eventArgs.endTime
      queryParam.deviceId = this.currentSelectedId
      queryHistoryCoordinates(queryParam).then((response) => {
        const data = response.data
        if (data.length === 0) {
          this.$warningEx('查询不到数据！')
        }
        this.mapPageParams.historyTrackPointsArray = data.locations.map((p) => {
          return { ...p, speed: Number(p.speed) / 10, lat: p.coordinates[1], lng: p.coordinates[0] }
        })

        this.mapPageParams.currentTrackObject = this.objectInfo
      })
    },
    // 轨迹播放停止
    handleMapTrackPlayStop() {
      this.$refs.monitorRef.mapTrackPlayStop()
    }
  }
}
</script>
